@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/gotham-pro.woff2') format('woff2'),
    url('../fonts/gotham-pro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Flaticon Base';
  src: url('../fonts/flaticon-base.woff2') format('woff2'),
    url('../fonts/flaticon-base.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Flaticon Arrows';
  src: url('../fonts/flaticon-arrows.woff2') format('woff2'),
    url('../fonts/flaticon-arrows.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Flaticon Social';
  src: url('../fonts/flaticon-social.woff2') format('woff2'),
    url('../fonts/flaticon-social.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
